
import { defineComponent } from "vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import settingsApp from "@/libs/settings";
import sessionApp from "@/libs/session";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();
    const { createLoading, openToast, registerDevice } = useGlobalFunctions();

    const confirmAccount = async () => {
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("confirmAccount"), {
          id: route.query["id"],
          key: route.query["key"],
        })
        .then((resp: any) => {
          if (resp.success) {
            openToast(t("account.confirmedMsg"), "success");
            const getCustomerDataPromise = sessionApp.getCustomerData();
            getCustomerDataPromise
              .then((customerDataResp: any) => {
                loading.dismiss();
                storeX.commit("setCustomerData", customerDataResp);
                registerDevice();
                router.replace("/account");
              })
              .catch(() => {
                loading.dismiss();
                registerDevice();
                router.replace("/account");
              });
          } else {
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
            } else {
              openToast(resp.error.message, "danger");
            }
            router.replace("/account");
          }
        })
        .catch((error) => {
          loading.dismiss();
          router.replace("/account");
          console.log(error, "error");
        });
    };

    onIonViewWillEnter(() => {
      confirmAccount();
    });

    return {
      t,
    };
  },
  components: {
    IonPage,
    IonContent,
  },
});
